import React from "react"
import { Link } from "gatsby"
import cn from "classnames"
import "./RelatedEventCard.scss"

const RelatedEventCard = ({ item }) => {
  const eventCategories = item.categories_names.filter(category => category.taxonomy === "types")
  const eventLocation = item.acf.event.event_venue && item.acf.event.event_venue
  const eventYear = item.categories_names.filter(category => category.taxonomy === "years")

  return (
    <div
      className={cn("event-slide col-12 col-md-6 col-lg-4 mb-2 mb-lg-0 text-sm")}
      key={item.title}
    >
      <Link
        to={`/events/${item.slug}`}
        key={item.slug}
        data-location={eventLocation}
        data-year={eventYear.length && eventYear[0].name}
        data-type={eventCategories.length && eventCategories[0].name}
      >
        <div className="event-header">
          <div className="event-title">
            <span className="bracket-text d-block">{eventCategories.length && eventCategories[0].name}</span>
            <span>{item.title}</span>
            {
              item.acf.event.event_subtitle && <span>{` • ${item.acf.event.event_subtitle}`}</span>
            }
          </div>
          <div className="event-info">
            {item.acf.event.speaker_name && <h3 className="d-block">{item.acf.event.speaker_name}</h3>}
            {
              item.acf.event.event_date_info && (
                <span>
                  {item.acf.event.event_date_info.event_date_info_start_date && item.acf.event.event_date_info.event_date_info_start_date}
                  {item.acf.event.event_date_info.event_date_info_start_time && ` ${item.acf.event.event_date_info.event_date_info_start_time}`}
                  {item.acf.event.event_date_info.event_date_info_end_date && ` – ${item.acf.event.event_date_info.event_date_info_end_date}`}
                  {item.acf.event.event_date_info.event_date_info_end_time && ` ${item.acf.event.event_date_info.event_date_info_end_time}`}
                </span>
              )
            }
            {eventLocation && <span className="d-block">{eventLocation}</span>}
          </div>
        </div>
        {
          item.excerpt && item.excerpt.length && <div className="event-body mt-1" dangerouslySetInnerHTML={{ __html: item.excerpt }} />
        }
      </Link>
    </div>
  )
}

export default RelatedEventCard
