import React, {useState} from "react"
import Swiper from "react-id-swiper"
import {Navigation} from "swiper/dist/js/swiper.esm"
import Button from "Accessories/Button/Button"
import PropTypes from "prop-types"
import "./EventSlider.scss"

const EventSlider = ({
  settings,
  children,
  headline,
  classes
}) => {

  const [currentSlideCount, setCurrentSlideCount] = useState(1)
  const [swiper, updateProjectSwiper] = useState(null)
  const totalSlides = children.length

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext()
      setCurrentSlideCount(swiper.activeIndex + 1)
    }
  }

  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev()
      setCurrentSlideCount(swiper.activeIndex + 1)
    }
  }

  return (
    <div className="section-project-slider section">
      {
        headline && <h2 className="bracket-text">{headline}</h2>
      }
      <div className="d-flex justify-content-between align-items-center">
        <div className="swiper-controls-wrapper ml-auto mb-1">
          <div className="swiper-controls">
            <Button
              className={`swiper-prev ${currentSlideCount === 1 ? "is-deactivated" : ""}`}
              text="Prev"
              onClick={goPrev}
            />
            <Button
              className={`swiper-next d-md-none ${currentSlideCount === totalSlides ? "is-deactivated" : ""}`}
              text="Next"
              onClick={goNext}
            />
            <Button
              className={`swiper-next d-none d-md-inline ${currentSlideCount === (totalSlides - 2) ? "is-deactivated" : ""}`}
              text="Next"
              onClick={goNext}
            />
          </div>
        </div>
      </div>
      <Swiper
        getSwiper={updateProjectSwiper}
        modules={[Navigation]}
        {...settings}
        containerClass={classes}
      >
        { children }
      </Swiper>
    </div>
  )

}

EventSlider.propTypes = {
  settings: PropTypes.instanceOf(Object)
}

EventSlider.defaultProps = {
  settings: {
    grabCursor: true,
    slidesPerView: "auto",
    loop: false
  }
}

export default EventSlider
